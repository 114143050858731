<template>
  <CContainer fluid class="mainTableContainer">
    <CRow alignVertical="center" alignHorizontal="center">
      <CCol lg="12" class="mainTableColumn">
        <CSelect
          label="Hotel"
          :options="selectHotelList"
          @update:value="changeHotel"
        ></CSelect>
        <CDataTable
          :noItemsView="{
            noResults: 'no items found',
            noItems: 'no items',
          }"
          :hover="true"
          :striped="true"
          :items-per-page="20"
          columnFilter
          :items="SnapshotList"
          sorter
          pagination
          :clickableRows="true"
          :fields="fields"
          size="sm"
        >
          <template #hotel="{ item }">
            <td>
              {{ getHotelNameByID(item.hotel_id) }}
            </td>
          </template>
          <template #user="{ item }">
            <td>
              {{ getUserNameByID(item.user_id) }}
            </td>
          </template>
          <template #updated_at="{ item }">
            <td>
              <Lock
                :size="20"
                @click.stop="disableSnapshot(item)"
                v-if="item.locked"
              ></Lock>
              <LockOpen
                :size="20"
                @click.stop="disableSnapshot(item)"
                v-else
              ></LockOpen>
              <Delete
                :size="20"
                @click.stop="deleteSnapshotShow(item.id, item.hotel_id)"
              ></Delete>
            </td>
          </template>
        </CDataTable>
      </CCol>
    </CRow>
    <DeleteConfirmModal
      :show="showDelete"
      @close="hideDelete"
      @accept="deleteItem"
    />
  </CContainer>
</template>

<script>
import Lock from "vue-material-design-icons/Lock.vue";
import LockOpen from "vue-material-design-icons/LockOpen.vue";
import Delete from "vue-material-design-icons/Delete.vue";
import DeleteConfirmModal from "@/components/modals/DeleteConfirmModal";
import { mapGetters } from "vuex";
import { CDataTable, CRow, CCol, CContainer, CSelect } from "@coreui/vue/src";
import { userModule } from "@/store/modules/userModule";
import { hotelModule } from "../store/modules/hotelModule";
import { snapshotModule } from "../store/modules/snapshotModule";

export default {
  name: "SnapshotListComponent.vue",
  computed: {
    ...mapGetters("hotelModule", ["getHotelListData"]),
    ...mapGetters("snapshotModule", ["getSnapshots"]),
    ...mapGetters("userModule", ["getUserListData"]),
    selectHotelList() {
      return this.getHotelListData.reduce((acc, item) => {
        let option = {
          label: item.name,
          value: item.id,
        };
        acc.push(option);
        return acc;
      }, []);
    },
    SnapshotList() {
      return this.getSnapshots.filter((x) => x.hotel_id === this.selectedHotel);
    },
  },
  components: {
    Lock,
    Delete,
    CDataTable,
    CRow,
    CCol,
    CContainer,
    CSelect,
    DeleteConfirmModal,
    LockOpen,
  },
  methods: {
    ...userModule.mapActions(["getUserList"]),
    ...hotelModule.mapActions(["getHotelList"]),
    ...snapshotModule.mapActions([
      "getSnapshotList",
      "deleteSnapshot",
      "updateSnapshot",
    ]),
    getHotelNameByID(id) {
      const candidate = this.getHotelListData.find((x) => x.id === id);
      let name = "";
      if (candidate) {
        name = candidate.name;
      }
      return name;
    },
    getUserNameByID(id) {
      const candidate = this.getUserListData.find((x) => x.id === id);
      let name = "";
      if (candidate) {
        name = candidate.name;
      }
      return name;
    },

    hideDelete() {
      this.showDelete = false;
      this.deleteItemID = null;
    },
    async deleteItem() {
      await this.deleteSnapshot(this.deleteItemID);
      this.deleteItemID = null;
      this.showDelete = false;
    },
    changeHotel(hotel_id) {
      this.selectedHotel = hotel_id;
    },
    async disableSnapshot(item) {
      if (item.locked) {
        item.locked = 0;
      } else {
        item.locked = 1;
      }
      await this.updateSnapshot(item);
    },
    deleteSnapshotShow(id) {
      this.deleteItemID = id;
      this.showDelete = true;
    },
  },
  async mounted() {
    if (!this.getHotelListData.length) {
      await this.getHotelList();
    }
    this.selectedHotel = this.getHotelListData[0].id;
    if (!this.getUserListData.length) {
      await this.getUserList();
    }
    await this.getSnapshotList();
  },
  data() {
    return {
      showDelete: false,
      deleteItemID: null,
      selectedHotel: null,
      fields: [
        { label: "ID", key: "id", sorter: true, filter: true },
        { label: "Hotel", key: "hotel", sorter: true, filter: true },
        { label: "User", key: "user", sorter: true, filter: true },
        { label: "Options", key: "updated_at", sorter: false, filter: false },
      ],
    };
  },
};
</script>

<style scoped lang="scss"></style>
