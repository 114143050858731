var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CContainer',{staticClass:"mainTableContainer",attrs:{"fluid":""}},[_c('CRow',{attrs:{"alignVertical":"center","alignHorizontal":"center"}},[_c('CCol',{staticClass:"mainTableColumn",attrs:{"lg":"12"}},[_c('CSelect',{attrs:{"label":"Hotel","options":_vm.selectHotelList},on:{"update:value":_vm.changeHotel}}),_c('CDataTable',{attrs:{"noItemsView":{
          noResults: 'no items found',
          noItems: 'no items',
        },"hover":true,"striped":true,"items-per-page":20,"columnFilter":"","items":_vm.SnapshotList,"sorter":"","pagination":"","clickableRows":true,"fields":_vm.fields,"size":"sm"},scopedSlots:_vm._u([{key:"hotel",fn:function(ref){
        var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getHotelNameByID(item.hotel_id))+" ")])]}},{key:"user",fn:function(ref){
        var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getUserNameByID(item.user_id))+" ")])]}},{key:"updated_at",fn:function(ref){
        var item = ref.item;
return [_c('td',[(item.locked)?_c('Lock',{attrs:{"size":20},on:{"click":function($event){$event.stopPropagation();return _vm.disableSnapshot(item)}}}):_c('LockOpen',{attrs:{"size":20},on:{"click":function($event){$event.stopPropagation();return _vm.disableSnapshot(item)}}}),_c('Delete',{attrs:{"size":20},on:{"click":function($event){$event.stopPropagation();return _vm.deleteSnapshotShow(item.id, item.hotel_id)}}})],1)]}}])})],1)],1),_c('DeleteConfirmModal',{attrs:{"show":_vm.showDelete},on:{"close":_vm.hideDelete,"accept":_vm.deleteItem}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }